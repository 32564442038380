
body {
  margin:0; 
  overflow: hidden;
  font-family: sans-serif;
}

/* ~~~~~~~~~~~~~~~~~~~~~~Header~~~~~~~~~~~~~~~~~~~~~~ */

/* Mobile */
@media only screen and (max-width: 1024px) {
  #navbar {
    width: 100%;
  }
}

/* Desktop */
@media only screen and (min-width: 1025px) {
  #navbar {
    width: calc(100% - 16px);
  }
}

/* DropDown */
.dropdown {
  position: relative;
  cursor: pointer;
  padding: 8px;
}

.dropdown:hover {
  background-color: lightgray;
  border-radius: 10px;
}

.menu {
  position: absolute;
  border: 1px solid #c9d2d3;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 9%);
  border-radius: 4px;
  background-color: #fff;
  padding: 16px;
  min-width: 200px;
  cursor: default;
  right: 0;
  cursor: pointer;
  box-sizing: border-box;

  list-style-type: none;
}

.dropdown-links {
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  text-decoration: none;
  color: #343342;
  padding-bottom: 8px;
  padding-top: 8px;
  white-space: nowrap;
  text-transform: capitalize;
}

.dropdown-links:hover {
  background-color: lightgray;
  border-radius: 10px;
}

.fa-angle-down {
  transition: 0.5s;
}

.fa-angle-down.rotate {
  transform:rotate(-180deg);
}

.login-link {
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  text-decoration: none;
  color: #343342;
  padding-bottom: 8px;
  padding-top: 8px;
  white-space: nowrap;
  text-transform: capitalize;
}

/* ~~~~~~~~~~~~~~~~~~~~~~Active~~~~~~~~~~~~~~~~~~~~~~ */

.active-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}

@media only screen and (max-width: 1024px) {
  .active-grid {
    grid-template-columns: 1fr;
    margin-right: 25px;
    margin-left: 25px;
    padding-bottom: 100px;
  }
}

.active-div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid;
  border-color: black;
  overflow: hidden;
  width: 100%;
  height: 300px;
}

.active-header {
  position: absolute;
  padding-left: 15px;
  padding-right: 15px;
  top: 0px;
  left: 0px;
  background-color: #333;
  opacity: 0.75;
  height: 45px;
  pointer-events: none;
}

.active-amount {
  color: white;
  opacity: 1;
}

.active-footer {
    position: absolute;
    padding-left: 15px;
    bottom: 0px;
    background-color: #333;
    opacity: 0.75;
    width: 100%;
    height: 55px;
    pointer-events: none;
}

.active-amount {
  color: white;
  opacity: 1;
}

.active-address {
  font-size: 1.5rem;
  color: white;
  opacity: 1;
}

.active-zipcode {
  color: white;
  opacity: 1;
  font-size: 1rem;
}

.active-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: .5s ease;
}

.active-img:hover {
  transform: scale(1.5);
  cursor: pointer
}

.active-sold {
  position: absolute;
  top: 0;
  right: -1px;
  padding: 50px;
  color:white;
  background-color: red;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 0%);
  text-align: right;
}

.active-sold-text {
  transform: translate(15px, -40px);
  rotate: calc(40deg);
}

/* ~~~~~~~~~~~~~~~~~~~~~~Login~~~~~~~~~~~~~~~~~~~~~~ */

.login-email {
  width: 90%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.login-password {
  width: 90%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-submit {
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  padding: 8px 16px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~Update~~~~~~~~~~~~~~~~~~~~~~ */
.update-input {
  width: 90%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* ~~~~~~~~~~~~~~~~~~~~~~TestimonialReadMore~~~~~~~~~~~~~~~~~~~~~~ */

@keyframes marquee {
	0%   { transform: translateX(0%); }
	100% { transform: translateX(-100%); }
}

.logo-slider {
  overflow: hidden;
  width: 100%;
  margin: 0 auto;	
	display: flex;
}

.logo-slider-div {
  display: flex;
	position: relative;
	animation: marquee 35s linear infinite;
}

.logo-slider:hover div {
  animation-play-state: paused;
}

.logo-slider-img {
  display: block;
  height: 400px;
}

.testimonial-container {
  max-width: 650px;
  font-size: 20px;
  background: white;
  text-align: center;
  padding: 25px;
  margin-bottom: 35px;
  border-radius: 15px;
}

.testimonial-readmore-div {
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  margin-top: 15px;
  text-decoration: none;
  color: #343342;
  padding-bottom: 8px;
  padding-top: 8px;
  white-space: nowrap;
  text-transform: capitalize;
}

.testimonial-readmore-div:hover {
    background-color: lightgray;
    border-radius: 10px;
}

.testimonial-signature {
  margin-top: 15px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~Contact~~~~~~~~~~~~~~~~~~~~~~ */

.contact-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1000px;
  font-size: 20px;
  background: white;
  text-align: center;
  padding: 25px;
  margin-bottom: 35px;
  border-radius: 15px;
}